import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getUserProfile, getCurrentlySignedInUser, getDocket, addJob, getDB } from '../api/FirebaseAPI';
import '../css/Details.css';
import CustomButton from '../components/CustomButton';
import Overlay from '../components/Overlay';
import { currentInvoice } from '../States';
import { useRecoilState } from 'recoil';

const Details = ({ match }) => {

    const [docket, setDocket] = useState();
    const [selectedItems, setSelectedItems] = useState([]);
    const [subtotal, setSubtotal] = useState(0);
    const [gst, setGST] = useState(0);
    const [includeGST, setIncludeGST] = useState(true);
    const [total, setTotal] = useState(0);
    const [redirect, setRedirect] = useState(false);

    const [user, setUser] = useState();
    // const [profile, setProfile] = useState();
    // const [loading, setLoading] = useState(true);
    const [overlay, setOverlay] = useState(false);
    const [items, setItems] = useState([]);

    const [invoice, setInvoice] = useRecoilState(currentInvoice);


    let history = useHistory();

    const calculateTotals = () => {
        //calculate the totals
        let sub = 0;
        items.forEach(item => {
            sub = sub + item.amount;
        });

        let g = includeGST ? (sub * 15 / 100).toFixed(2) : 0;
        let ttl = parseFloat(g) + parseFloat(sub);
        setSubtotal(parseFloat(sub).toFixed(2));
        setGST(parseFloat(g).toFixed(2))
        setTotal(parseFloat(ttl).toFixed(2));
    }

    useEffect(() => {
        getCurrentlySignedInUser(setupUser, () => redirectToLogin());

        if (user) {
            const ref = getDB().collection('dockets: ' + user.uid).doc(match.params.id).collection('jobs: ' + match.params.id).orderBy('timeStamp');

            return ref.onSnapshot(querySnapshot => {
                const list = [];

                querySnapshot.forEach(doc => {
                    let data = doc.data();

                    list.push({
                        ...data, id: doc.id
                    });
                });

                setItems(list);


            });
        }



    }, [user]);

    useEffect(() => {
        if (redirect) history.push('/submition');
    }, [redirect]);

    useEffect(() => {
        calculateTotals();
    }, [calculateTotals, items, includeGST]);


    const setupUser = (user) => {
        setUser(user);
        getUserProfile().doc(user.uid).get().then((doc) => {
            if (doc.exists) {
                // setProfile(doc.data());
            }
        });

        getDocket(user.uid, match.params.id, doc => {
            if (doc.exists) {
                setDocket(doc.data());
            }

        }, error => {
            console.log(error);
        });

        // setLoading(false);

    }



    const redirectToLogin = () => {
        history.push("/");
    }

    const openOverlay = () => {
        setOverlay(true);
    }

    const closeOverlay = () => {
        setOverlay(false);
    }



    const newRow = data => {
        addJob(user.uid, match.params.id, data, () => {
            //job added
            calculateTotals();

        }, (error) => {
            alert(error);
        })
    }

    const selectRow = (evt, id) => {
        //check if item is already selected
        if (selectedItems.includes(id)) {
            //remove from array
            let index = selectedItems.indexOf(id);
            selectedItems.splice(index, 1);
            let temparray = Array.from(selectedItems);
            setSelectedItems(temparray);
        } else {
            //add to array
            selectedItems.push(id);
            let temparray = Array.from(selectedItems);
            setSelectedItems(temparray);
        }

    }

    const isSelected = (id) => {
        if (selectedItems.includes(id)) return true;

        return false;
    }

    const deleteSelected = () => {
        //prompt deletion

        var batch = getDB().batch();

        //make deletions
        selectedItems.forEach(id => {
            var ref = getDB().collection('dockets: ' + user.uid).doc(match.params.id).collection('jobs: ' + match.params.id).doc(id);
            batch.delete(ref);
        });

        //complete
        batch.commit().then(() => {
            //refresh selected list
            setSelectedItems([]);
        }).catch(error => {
            console.log("error deleting dockets: ", error);
        });
    }

    const handleSubmition = () => {
        if (items.length <= 0) return;

        let newInvoice = {
            ...docket,
            lines: [...items],
            email: user.email,
            docketID: match.params.id,
            gstAmount: gst,
            total: total,
            subtotal: subtotal,
        };

        setInvoice(newInvoice);
        setRedirect(true);
    }

    return (
        <div className="detail-container">

            <Overlay closeOverlay={closeOverlay}
                onSubmit={newRow}
                isVisible={overlay}
            />


            <h1>Details</h1>

            <div className="detail-date">
                <h3>Date:</h3>
                <p>{docket && docket.createdAt}</p>
            </div>

            <div className="detail-header">
                <table>
                    <tr>
                        <td> <h4>To:</h4> </td>
                        <td> {docket && docket.to} </td>
                    </tr>
                    <tr>
                        <td> <h4>Invoice Number:</h4></td>
                        <td> SGC-INV-XXX </td>
                    </tr>
                    <tr>
                        <td> <h4>Issued:</h4> </td>
                        <td> {docket && docket.issued}</td>
                    </tr>
                    <tr>
                        <td> <h4>Due:</h4></td>
                        <td> {docket && docket.due}</td>
                    </tr>
                    <tr>
                        <td> <h4>GST Number:</h4></td>
                        <td> {docket && docket.gst}</td>
                    </tr>
                </table>


                <table>
                    <tr>
                        <td className="table-top"><h4>From:</h4></td>
                        {docket && <td>
                            <p>{docket.company}</p>
                            <p>{docket.address}</p>
                            <p>{docket.suburb}</p>
                            <p>{docket.city}</p>
                            <p>{docket.code}</p>
                            <p>{docket.country}</p>
                        </td>}
                    </tr>
                </table>
            </div>

            {/* <div className="detail-exports">
                <CustomButton title="PDF" type="btn-secondary" />
                <CustomButton title="Excel" type="btn-secondary" />
            </div> */}

            <div className="detail-options">
                <label htmlFor='gst_included' onClick={() => setIncludeGST(prev => !prev)}>
                    <input name="gst_included" type="checkbox" checked={includeGST} />
                    Include GST
                </label>
            </div>

            <div className="detail-item-container">
                <table className="detail-item-table">
                    <thead>
                        <tr>
                            <th>
                                Description
                            </th>
                            <th>
                                QTY
                            </th>
                            <th>
                                Unit
                            </th>
                            <th>
                                NZD
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {items.map(item => {
                            return (
                                <tr className={isSelected(item.id) ? "selected" : ""} key={item.id} onClick={evt => { selectRow(evt, item.id) }}>
                                    <td>
                                        {item.desc}
                                    </td>
                                    <td>
                                        {item.quantity}
                                    </td>
                                    <td>
                                        ${parseFloat(item.unit).toFixed(2)}
                                    </td>
                                    <td>
                                        ${item.amount.toFixed(2)}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>


            </div>

            <div className="subtotal-container">
                {items.length > 0 ?
                    <table className="subtotal-table">
                        <tr>
                            <td><h4>Subtotal:</h4></td>
                            <td className="align-totals"><p>${subtotal}</p></td>
                        </tr>
                        <tr>
                            <td><h4>Total GST 15%:</h4></td>
                            <td className="align-totals"><p>${gst}</p></td>
                        </tr>
                        <tr>
                            <td><h3>Amount Due:</h3></td>
                            <td className="align-totals"><h3>NZD: ${total}</h3></td>
                        </tr>
                    </table>
                    : null}
            </div>


            <div className="detail-footer">
                <CustomButton title="Delete Selected" onClick={deleteSelected} />
                <CustomButton title="Add New Row" onClick={openOverlay} />
                <CustomButton title="Submit" onClick={handleSubmition} />
            </div>
        </div>
    );
}

export default Details;