import React from 'react';
import './App.css';
import Login from './screens/Login';
import Dashboard from './screens/Dashboard';
import { BrowserRouter as Router, Switch, Route, } from 'react-router-dom';
import Nav from './components/Nav';
import NewDocket from './screens/NewDocket';
import SignUp from './screens/SignUp';
import Dockets from './screens/Dockets';
import Details from './screens/Details';
import EditDocket from './screens/EditDocket';
import Submition from './screens/Submition';
import CompanyInfo from './screens/CompanyInfo';
import Clients from './screens/Clients';
import Auth from './components/Auth';
import { RecoilRoot } from 'recoil';

function App() {


  return (
    <RecoilRoot>
      <Router >
        <Auth />
        <div className="app" >
          <Nav />
          <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/newdocket" component={NewDocket} />
            <Route path="/dockets" exact component={Dockets} />
            <Route path="/dockets/:id" component={Details} />
            <Route path="/editdocket/:id" component={EditDocket} />
            <Route path="/submition" component={Submition} />
            <Route path="/info" component={CompanyInfo} />
            <Route path="/clients" component={Clients} />
          </Switch>
        </div>
      </Router>
    </RecoilRoot>
  );
}

export default App;
