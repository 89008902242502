import React, { useState, useEffect } from 'react';
import '../css/CompanyInfo.css';
import { useHistory } from 'react-router-dom';
import { getDB } from '../api/FirebaseAPI';
import { currentUser } from '../States';
import { useRecoilValue } from 'recoil';


const CompanyInfo = () => {
    const [company, setCompany] = useState('');
    const [address, setAddress] = useState('');
    const [suburb, setSuburb] = useState('');
    const [city, setCity] = useState('');
    const [code, setCode] = useState('');
    const [country, setCountry] = useState('');
    const [error, setError] = useState("");
    const [gst, setGST] = useState("");
    const [acc, setAcc] = useState('');

    const [profile, setProfile] = useState(false);

    const [labels, setLabels] = useState(['company', 'address', 'suburb', 'city', 'code', 'country', 'gst', 'acc']);

    const user = useRecoilValue(currentUser);

    let history = useHistory();

    useEffect(() => {
        if (user) {
            getProfile();
        } else {
            history.push("/");
        }
    }, [user]);

    useEffect(() => {
        if (profile) {
            if (profile?.info)
                init();
        }
    }, [profile])


    const getProfile = () => {
        var db = getDB();
        db.collection('users').doc(user.uid).get()
            .then(doc => {
                if (doc.exists) {
                    console.log("data: ", doc.data());
                    setProfile(doc.data());
                } else {
                    console.log("doc not found");
                }
            }).catch(error => {
                console.error(error);
            })
    }

    const init = () => {
        setCompany(profile?.info?.company);
        setAddress(profile?.info?.address);
        setSuburb(profile?.info?.suburb);
        setCity(profile?.info?.city);
        setCode(profile?.info?.code);
        setCountry(profile?.info?.country);
        setGST(profile?.info?.gst);
        setAcc(profile?.info?.acc);
    }

    const getValue = item => {
        switch (item) {
            case 'company':
                return company;
            case 'address':
                return address;
            case 'suburb':
                return suburb;
            case 'city':
                return city;
            case 'code':
                return code;
            case 'country':
                return country;
            case 'gst':
                return gst;
            case 'acc':
                return acc;
        }
    }

    const submit = (e) => {
        e.preventDefault();

        if (!validate()) {
            setError("invalid inputs!");
            return;
        }

        let ref = getDB().collection('users').doc(user.uid);
        let info = {
            company: company,
            address: address,
            suburb: suburb,
            city: city,
            code: code,
            country: country,
            gst: gst,
            acc: acc,
        };

        ref.update({ info: info }).then(() => {
            history.push("/dashboard");
        }).catch(error => {
            setError(error.toString());
        });


    }

    const updateValue = e => {
        setError("");
        switch (e.target.name) {
            case 'company':
                setCompany(e.target.value);
                return;
            case 'address':
                setAddress(e.target.value);
                return;
            case 'suburb':
                setSuburb(e.target.value);
                return;
            case 'city':
                setCity(e.target.value);
                return;
            case 'code':
                setCode(e.target.value);
                return;
            case 'country':
                setCountry(e.target.value);
                return;
            case 'gst':
                setGST(e.target.value);
                return;
            case 'acc':
                setAcc(e.target.value);
                return;
        }
    }

    const validate = () => {
        return true;
    }

    return (
        <div className="info-container">
            <form className="info-card">
                <h3>Company Information</h3>
                {
                    labels.map((item, index) => {
                        return (
                            <div className="info-item" key={index}>
                                <input autoComplete="off" name={item} type="text" placeholder={item.charAt(0).toUpperCase() + item.slice(1)} value={getValue(item)} onChange={updateValue} />
                            </div>
                        )
                    })
                }
                <input onClick={submit} type="submit" value="Save Changes" className="info-button" />
                {error !== "" ? <p className="error">{error}</p> : ''}
            </form>

        </div>
    );
}

export default CompanyInfo;