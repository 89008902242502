import React, { useState, useEffect } from 'react';

import "../css/Clients.css";
import TableForm from '../components/client/TableForm';
import { getDB } from '../api/FirebaseAPI';
import { useRecoilValue } from 'recoil';
import { currentUser } from '../States';
import TableItem from '../components/client/TableItem';

const Clients = () => {

    const [search, setSearch] = useState("");
    const [addForm, setAddForm] = useState(false);
    const [profile, setProfile] = useState({});
    const [clients, setClients] = useState([]);

    const user = useRecoilValue(currentUser);

    useEffect(() => {
        if (user) {
            getProfile();
        }
    }, [user]);

    useEffect(() => {
        if (profile.clients) {
            setClients(profile.clients);
            console.log('clients', profile.clients);
        }
    }, [profile])

    const getProfile = () => {
        var db = getDB();
        db.collection('users').doc(user.uid).get()
            .then(doc => {
                if (doc.exists) {
                    //  console.log("data: ", doc.data());
                    setProfile(doc.data());
                } else {
                    console.log("doc not found");
                }
            }).catch(error => {
                console.error(error);
            })
    }

    const updateSearch = e => {
        setSearch(e.target.value);
        //perform search
    }

    const toggleAddForm = () => {
        setAddForm(prev => !prev);
    }

    const addClients = (client) => {
        let newClient = [...clients, client];

        let ref = getDB().collection('users').doc(user.uid);

        ref.update({ clients: newClient }).then(() => {
            console.log('new clients', newClient);
            setClients(newClient);
            toggleAddForm();
        }).catch(error => {
            console.error(error.toString());
        });
    }

    const updateClients = (newClients) => {
        let ref = getDB().collection('users').doc(user.uid);

        ref.update({ clients: newClients }).then(() => {
            setClients(newClients);
            deselectAll();
            console.log('new clients', newClients);
        }).catch(error => {
            console.error(error.toString());
        });
    }

    const changeSelection = (e) => {
        if (e.target.checked) {
            let checkboxes = document.getElementsByClassName('table-checkbox');
            for (const checkbox of checkboxes) {
                checkbox.checked = true;
            }
        } else {
            deselectAll();
        }
    }

    const removeAll = () => {
        //get selected names
        let checkboxes = document.getElementsByClassName('table-checkbox');
        let selectedIDs = [];
        for (const checkbox of checkboxes) {
            if (checkbox.checked) selectedIDs.push(checkbox.value);
        }
        let confirm = window.confirm("Are you sure you wish to delete " + selectedIDs.length + " items?");
        if (confirm) {
            let newClients = clients.filter(item => !selectedIDs.includes(item.id));
            updateClients(newClients);
        }
    }

    const deselectAll = () => {
        let checkboxes = document.getElementsByClassName('table-checkbox');
        for (const checkbox of checkboxes) {
            checkbox.checked = false;
        }
    }

    return (
        <div className="clients-container">

            <div className='clients-wrapper'>
                <h1>Clients</h1>

                <div className="client-search">
                    <input type="search" value={search} onChange={updateSearch} placeholder="Find" />
                </div>
                <div className='client-actions'>
                    <span className="checkbox">
                        <input onChange={changeSelection} type="checkbox" />
                    </span>
                    <p className="title">Name</p>
                    <p onClick={toggleAddForm} className="action">Add</p>
                    <p onClick={removeAll} className="action">Remove</p>
                </div>

                <TableForm
                    visible={addForm}
                    onCancel={toggleAddForm}
                    onSubmit={addClients}
                />

                {clients && clients.map((item, index) => {
                    return (
                        <TableItem
                            key={index}
                            data={item}
                        />
                    )
                })}
            </div>

        </div>
    );
}

export default Clients;