import React, { useState, useEffect } from 'react';
import { signIn } from '../api/FirebaseAPI';
import { useHistory, Link } from 'react-router-dom';
import '../css/Login.css';
import Spinner from '../components/Spinner';
import { currentUser } from '../States';
import { useRecoilValue } from 'recoil';
import SignUp from '../components/SignUp';


const Login = () => {
    const [error, setError] = useState(false);
    const [errMsg, setErrorMsg] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [isSignUp, setIsSignUp] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const user = useRecoilValue(currentUser);

    let history = useHistory();

    useEffect(() => {
        if (user) {
            history.push("/dashboard");
        }
    })

    const updateEmail = (e) => {
        setUsername(e.target.value);
        setError(false);
    }

    const updatePassword = (e) => {
        setPassword(e.target.value);
        setError(false);
    }

    const handleLogin = () => {
        setSubmitting(true);
        signIn(username, password, successfulLogin, unsuccessfulLogin);
    }

    const successfulLogin = (user) => {
        history.push("/dashboard");
        setSubmitting(false);
    }

    const unsuccessfulLogin = (error) => {
        setError(true);
        setErrorMsg(error);
        setSubmitting(false);
    }

    const toggleIsSignUp = () => {
        setIsSignUp(!isSignUp);
    }

    return (
        <div className="login-container" >
            <div className="login-card">
                <div className={isSignUp ? "login-glassPane move-right" : "login-glassPane"}>
                    <h3>Invoice Builder</h3>
                    <p>Innovative and fast way to create proffesional invoices</p>
                    <span onClick={toggleIsSignUp}>{isSignUp ? 'sign in' : 'create account'}</span>
                </div>
                <div className={isSignUp ? "login-form move-left" : "login-form"}>
                    {isSignUp ? <SignUp /> : <>
                        <h3>Sign Into Invoice Builder</h3>
                        <input type="email" name="email" placeholder="Email" onChange={updateEmail} />
                        <input type="password" name="password" placeholder="Password" onChange={updatePassword} />
                        <span className="loading" onClick={handleLogin} >{submitting ? <Spinner size="10px" /> : "Sign In"}</span>
                        <p>Forgot Password?</p>
                        {error && <p className="error">{errMsg}</p>}
                    </>}

                </div>
            </div>
        </div>
    );
}

export default Login;
