import React, { useEffect, useState } from 'react';
import '../css/DocketItem.css';
import { getDB } from '../api/FirebaseAPI';
import { currentInvoice, currentUser } from '../States';
import { useRecoilValue } from 'recoil';

const DocketItem = ({ data, onEdit, onDelete, onClick }) => {

    const [trans, setTrans] = useState([]);
    const [balance, setBalance] = useState(0);
    const user = useRecoilValue(currentUser);

    const handleClick = (e) => {
        console.log("evt:", e.target.id);
        if (e.target.id === 'icon') return;

        onClick(data);
    }

    const handleEdit = (e) => {
        e.stopPropagation();
        onEdit(data.id);
    }

    const handleDelete = (e) => {
        e.stopPropagation();
        onDelete(data.id);
    }

    useEffect(() => {
        if (data && user) {
            //get trans
            const ref = getDB().collection('dockets: ' + user.uid).doc(data.id).collection('jobs: ' + data.id).orderBy('timeStamp');

            return ref.onSnapshot(querySnapshot => {
                const list = [];

                querySnapshot.forEach(doc => {
                    let data = doc.data();

                    list.push({
                        ...data, id: doc.id
                    });
                });

                setTrans(list);

            });
        }
    }, [])

    useEffect(() => {
        if (trans.length > 0) {
            //calculate balance
            let sub = 0;
            trans.forEach(item => {
                sub = sub + item.amount;
            });

            let g = (sub * 15 / 100).toFixed(2);
            let ttl = parseFloat(g) + parseFloat(sub);
            setBalance(ttl.toFixed(2));
        }
    }, [trans])

    return (
        <div className="docket-item" onClick={handleClick} id='docket-item'>
            <div className="docket-item-icons" id="icon">
                <p className="docket-item-icon-style" onClick={handleEdit} >Edit</p>
                <p>|</p>
                <p className="docket-item-icon-style" onClick={handleDelete} >Delete</p>
            </div>

            <div className="docket-item-table-container">
                <div className="docket-item-header">{data.to}</div>
                <div className="docket-item-subtitle">#SVG-INV-342</div>

                <div className="docket-item-title">Issued</div>
                <div className="docket-item-subtitle">{data.issued}</div>

                <div className="docket-item-title">Due</div>
                <div className="docket-item-subtitle">{data.due}</div>

                <div className="docket-item-balance">${balance}</div>
            </div>


            <div className=" docket-date">
                {data.createdAt}
            </div>
        </div>
    );
}

export default DocketItem;