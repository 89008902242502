import React from 'react';

const TableItem = ({ data }) => {
    return (
        <div style={container}>

            <span style={checkbox} >
                <input type="checkbox" value={data.id} className="table-checkbox" />
            </span>
            <div style={title} >
                {data && data.name}
            </div>
        </div>
    );
}

const container = {
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    backgroundColor: 'white',
    marginLeft: '5px',
    padding: '0.5rem',
    fontFamily: 'var(--base-font)',
    boxSizing: 'border-box',
    marginTop: '2px',
}

const checkbox = {
    display: 'flex',
    width: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px solid #cccccc',
    marginLeft: '0.5rem'
}

const title = {
    marginLeft: '20px',
    display: 'flex',
    flex: 1,
    padding: '5px',
}

export default TableItem;