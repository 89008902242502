import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getDB } from '../api/FirebaseAPI';
import "../css/Dockets.css";
import DocketItem from '../components/DocketItem.js';
import { currentUser } from '../States';
import { useRecoilValue } from 'recoil';

const Dockets = () => {

    const myUser = useRecoilValue(currentUser);
    const [dockets, setDockets] = useState([]);


    let history = useHistory();

    useEffect(() => {
        setupUser(myUser);
    }, []);

    const setupUser = (user) => {
        // setUser(user);
        const ref = getDB().collection('dockets: ' + user.uid).orderBy('timeStamp');

        return ref.onSnapshot(querySnapshot => {
            const list = [];

            querySnapshot.forEach(doc => {
                let data = doc.data();

                list.push({
                    ...data, id: doc.id
                });
            });

            setDockets(list);


        });
    }


    const handleItemClick = (data) => {
        history.push("/dockets/" + data.id);
    }

    const handleEdit = (id) => {
        history.push("/editdocket/" + id);
    }

    const handleDelete = (id) => {
        var a = window.confirm('Are you sure you wish to delete this?');

        if (a) {
            getDB().collection('dockets: ' + myUser.uid).doc(id).delete().then(() => {
                //document is deleted
            }).catch(error => {
                console.log("Error deleting Job: ", error);
            });
        }


    }



    return (
        <div className="docket-container">

            <div className="docket-item-container">
                <h1>Invoices</h1>
                {dockets.map(item => {
                    return (
                        <DocketItem
                            key={item.id}
                            data={item}
                            onClick={handleItemClick}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default Dockets;