import React, { useState, useEffect } from 'react';
import '../css/NewDocket.css';
import CustomButton from "../components/CustomButton";
import { addDocket, incrementDocket, getDB } from '../api/FirebaseAPI';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { currentUser } from '../States';
import { useRecoilValue } from 'recoil';

const NewDocket = () => {
    const [to, setTo] = useState('');
    const [issued, setIssued] = useState(moment().format('DD/MM/YYYY'))
    const [due, setDue] = useState(moment().format('DD/MM/YYYY'));
    const [company, setCompany] = useState('');
    const [address, setAddress] = useState('');
    const [suburb, setSuburb] = useState('');
    const [city, setCity] = useState('');
    const [code, setCode] = useState('');
    const [country, setCountry] = useState('');
    const [error, setError] = useState("");
    const [gst, setGST] = useState('');
    const [acc, setAcc] = useState('');

    //billing to fields
    const [cAddress, setCAddress] = useState('');
    const [cSuburb, setCSuburb] = useState('');
    const [cCity, setCCity] = useState('');
    const [cPostCode, setCPostCode] = useState('');
    const [cCountry, setCCountry] = useState('');

    const user = useRecoilValue(currentUser);
    const [profile, setProfile] = useState();

    let history = useHistory();

    useEffect(() => {
        if (user) {
            getProfile();
        } else {
            history.push("/");
        }

    }, []);

    const getProfile = () => {
        var db = getDB();
        db.collection('users').doc(user.uid).get()
            .then(doc => {
                if (doc.exists) {
                    console.log("data: ", doc.data());
                    setProfile(doc.data());
                } else {
                    console.log("doc not found");
                }
            }).catch(error => {
                console.error(error);
            })
    }


    useEffect(() => {
        if (profile?.info) {
            setCompany(profile?.info?.company);
            setAddress(profile?.info?.address);
            setSuburb(profile?.info?.suburb);
            setCity(profile?.info?.city);
            setCode(profile?.info?.code);
            setCountry(profile?.info?.country);
            setGST(profile?.info?.gst);
            setAcc(profile?.info?.acc);
        }
    }, [profile])


    const updateAcc = (e) => {
        setAcc(e.target.value);
        setError('');
    }

    const updateGST = (e) => {
        setGST(e.target.value);
        setError('');
    }


    const updateTo = (e) => {
        if (profile.clients) {
            let c = profile.clients.filter(item => item.name === e.target.value);
            if (c.length > 0) {
                setCAddress(c[0].address);
                setCSuburb(c[0].suburb);
                setCCity(c[0].city);
                setCPostCode(c[0].postcode);
                setCCountry(c[0].country);
            }
        }

        setTo(e.target.value);
        setError("");
    }

    const updateIssued = (e) => {
        setIssued(e.target.value);
        setError("");
    }

    const updateDue = (e) => {
        setDue(e.target.value);
        setError("");
    }

    const updateCompany = (e) => {
        setCompany(e.target.value);
        setError("");
    }

    const updateAddress = (e) => {
        setAddress(e.target.value);
        setError("");
    }

    const updateSuburb = (e) => {
        setSuburb(e.target.value);
        setError("");
    }

    const updateCity = (e) => {
        setCity(e.target.value);
        setError("");
    }

    const updateCode = (e) => {
        setCode(e.target.value);
        setError("");
    }

    const updateCountry = (e) => {
        setCountry(e.target.value);
        setError("");
    }

    const updateCustomerAddress = e => {
        if (e.target.id == 'caddress') {
            setCAddress(e.target.value);
        }
        if (e.target.id == 'csuburb') {
            setCSuburb(e.target.value);
        }
        if (e.target.id == 'ccity') {
            setCCity(e.target.value);
        }
        if (e.target.id == 'cpostcode') {
            setCPostCode(e.target.value);
        }
        if (e.target.id == 'ccountry') {
            setCCountry(e.target.value);
        }
    }

    const submit = (e) => {
        e.preventDefault();

        if (!validate()) {
            setError("invalid inputs!");
            return;
        }

        let data = {
            to: to,
            issued: issued,
            due: due,
            company: company,
            address: address,
            suburb: suburb,
            city: city,
            code: code,
            country: country,
            createdAt: moment().format('MMMM Do YYYY'),
            gst: gst,
            acc: acc,
            customer_address: cAddress,
            customer_suburb: cSuburb,
            customer_city: cCity,
            customer_postcode: cPostCode,
            customer_country: cCountry
        };

        addDocket(user.uid, data, () => {
            //once added update profile here
            incrementDocket(user.uid);
            history.push("/dockets");
        }, err => {
            setError(error);
        })
    }

    const validate = () => {
        if (to.trim() === '') return false;
        if (issued.trim() === '') return false;
        if (due.trim() === '') return false;
        if (company.trim() === '') return false;
        if (address.trim() === '') return false;
        if (suburb.trim() === '') return false;
        if (city.trim() === '') return false;
        if (code.trim() === '') return false;
        if (country.trim() === '') return false;
        if (gst.trim() === '') return false;

        return true;
    }

    return (
        <div className="newdocket-container">
            <div className="newdocket-content">
                <div className="newdocket-section1">
                    <p className="dashboard-heading">
                        New Invoice
                    </p>
                    <div className="customer-billing">
                        <p className="dashboard-heading">
                            Customer Billing
                        </p>

                        <form action="">
                            <input type="text" list='client-list' placeholder="To" value={to} onChange={updateTo} autoComplete="off" />
                            <datalist id="client-list" >
                                {
                                    profile && profile.clients?.map((item, index) => {
                                        return <option value={item.name} key={index} />
                                    })
                                }
                            </datalist>
                            <input type="text" id="caddress" placeholder="Address" value={cAddress} onChange={updateCustomerAddress} autoComplete="off" />
                            <input type="text" id="csuburb" placeholder="Suburb" value={cSuburb} onChange={updateCustomerAddress} autoComplete="off" />
                            <input type="text" id="ccity" placeholder="City" value={cCity} onChange={updateCustomerAddress} autoComplete="off" />
                            <input type="text" id="cpostcode" placeholder="Post Code" value={cPostCode} onChange={updateCustomerAddress} autoComplete="off" />
                            <input type="text" id="ccountry" placeholder="Country" value={cCountry} onChange={updateCustomerAddress} autoComplete="off" />
                            <input type="text" placeholder="Issued" value={issued} onChange={updateIssued} autoComplete="off" />
                            <input type="text" placeholder="Due" value={due} onChange={updateDue} autoComplete="off" />

                            <input onClick={submit} type="submit" value="Create" className="newdocket-button" />
                            {error !== "" ? <p className="newdocket-error">{error}</p> : null}
                        </form>

                    </div>
                </div>

                <div className="newdocket-section2">
                    <div className="newdocket-company-details">
                        <p className="dashboard-heading-company">
                            Company Details
                        </p>

                        <form action="">
                            <input type="text" placeholder="Company" value={company} onChange={updateCompany} autoComplete="off" />
                            <input type="text" placeholder="Address" value={address} onChange={updateAddress} autoComplete="off" />
                            <input type="text" placeholder="Suburb" value={suburb} onChange={updateSuburb} autoComplete="off" />
                            <input type="text" placeholder="City" value={city} onChange={updateCity} autoComplete="off" />
                            <input type="text" placeholder="Post Code" value={code} onChange={updateCode} autoComplete="off" />
                            <input type="text" placeholder="Country" value={country} onChange={updateCountry} autoComplete="off" />
                            <input type="text" placeholder="GST #" value={gst} onChange={updateGST} autoComplete="off" />
                            <input type="text" placeholder="Bank Account" value={acc} onChange={updateAcc} autoComplete="off" />

                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewDocket;