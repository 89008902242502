import React, { useState } from 'react';
import '../css/Overlay.css';
import CustomInput from '../components/CustomInput';
import CustomButton from '../components/CustomButton';

const Overlay = (props) => {
    const [quantity, setQuantity] = useState('');
    const [desc, setDesc] = useState(0);
    const [rate, setRate] = useState(0);
    const [error, setError] = useState('');

    const updateQuantity = (e) => {
        setQuantity(parseFloat(e.target.value));
        setError("");
    }
    const updateDesc = (e) => {
        setDesc(e.target.value);
        setError("");
    }
    const updateRate = (e) => {
        setRate(parseFloat(e.target.value));
        setError("");
    }
    // const updateAmount = (e) => {
    //     setAmount(e.target.value);
    //     setError("");
    // }

    const closeOverlay = (e) => {
        if (e.target.id !== 'overlay-background') return;
        props.closeOverlay();
    }

    const submit = () => {
        let data = {
            quantity: quantity,
            desc: desc,
            unit: rate,
            amount: parseFloat(rate) * parseFloat(quantity),
            createdAt: new Date().toDateString(),
        };

        props.onSubmit(data);
        props.closeOverlay();
    }

    return (
        <div className={props.isVisible ? "overlay-container" : "overlay-container hidden"} onClick={closeOverlay} id="overlay-background">
            <div className="overlay-card" >
                <CustomInput label="Description" placeholder="Description" type="text" onChange={updateDesc} />
                <CustomInput label="QTY" placeholder="Quantity" type="number" onChange={updateQuantity} />
                <CustomInput label="Unit Price" placeholder="Unit Price" type="number" onChange={updateRate} />
                {/* <CustomInput label=" Amount NZD" placeholder="Amount" type="text" onChange={updateAmount} /> */}
                <CustomButton title="Create" type="btn-primary" onClick={submit} />
                <div className="overlay-error">
                    {error}
                </div>
            </div>
        </div>
    );
}

export default Overlay;