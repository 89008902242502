
import { atom } from 'recoil';

export const currentInvoice = atom({
    key: 'currentInvoice',
    default: {},
});

export const currentUser = atom({
    key: 'currentUser',
    default: undefined,
    dangerouslyAllowMutability: true,
})
