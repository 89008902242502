import React, { useEffect, useState } from 'react';
import '../css/RecentItem.css';
import { currentUser } from '../States';
import { useRecoilValue } from 'recoil';
import { getDB } from '../api/FirebaseAPI';
import { useHistory } from 'react-router';

const RecentItem = ({ data }) => {
    const [trans, setTrans] = useState([]);
    const [balance, setBalance] = useState(0);
    const user = useRecoilValue(currentUser);

    let history = useHistory();

    useEffect(() => {
        if (data && user) {
            //get trans
            const ref = getDB().collection('dockets: ' + user.uid).doc(data.id).collection('jobs: ' + data.id).orderBy('timeStamp');

            return ref.onSnapshot(querySnapshot => {
                const list = [];

                querySnapshot.forEach(doc => {
                    let data = doc.data();

                    list.push({
                        ...data, id: doc.id
                    });
                });

                setTrans(list);

            });
        }
    }, []);

    useEffect(() => {
        if (trans.length > 0) {
            //calculate balance
            let sub = 0;
            trans.forEach(item => {
                sub = sub + item.amount;
            });

            let g = (sub * 15 / 100).toFixed(2);
            let ttl = parseFloat(g) + parseFloat(sub);
            setBalance(ttl.toFixed(2));
        }
    }, [trans]);

    const handleClick = () => {
        history.push("/dockets/" + data.id);
    }

    return (
        <div className="ri-container" onClick={handleClick}>
            <p className="title">{data.to}</p>
            <p className="balance">${balance}</p>
            <p className="inv-num">#SVG-INV-XXX</p>
            <p className="issued">{data.issued}</p>
        </div>
    );
}

export default RecentItem;