import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import "../css/Dashboard.css";
import { getDockets, getDB } from '../api/FirebaseAPI';
import { currentUser } from '../States';
import { useRecoilValue } from 'recoil';
import RecentItem from '../components/RecentItem';


const Dashboard = () => {
    const [profile, setProfile] = useState(false);
    const [invoices, setInvoices] = useState([]);

    const user = useRecoilValue(currentUser);

    let history = useHistory();

    useEffect(() => {
        if (user) {
            getInvoices();
            getProfile();
        } else {
            history.push("/");
        }
    }, [])

    const getInvoices = () => {
        getDockets(user.uid, snapshot => {
            let list = [];
            snapshot.forEach(doc => {
                list.push({ ...doc.data(), id: doc.id });
            });

            // if (list.length > 4) {
            //     setInvoices(list.splice(0, 4));
            // } else {
            //     setInvoices(list);
            // }

            setInvoices(list);

            console.log("invoices: ", list);
        }, error => {
            console.error("error getting invoices::", error);
        });

    }

    const getProfile = () => {
        var db = getDB();
        db.collection('users').doc(user.uid).get()
            .then(doc => {
                if (doc.exists) {
                    //console.log("data: ", doc.data());
                    setProfile(doc.data());
                } else {
                    console.log("doc not found");
                }
            }).catch(error => {
                console.error(error);
            })
    }

    return (
        <div className="dashboard-container">
            <div className="dashboard-content">
                <div className="grid-section1">
                    <p className="dashboard-heading">
                        Dashboard
                    </p>
                </div>
                <div className="grid-section2">
                    <div className="recent-invoices">
                        {invoices.map((item, index) => {
                            return (
                                <RecentItem key={index} data={item} />
                            );
                        })}
                    </div>
                    <p className="dashboard-text">
                        Recent Invoices
                    </p>
                </div>
                <div className="grid-section3">
                    <div className="user-details">
                        <p className="dashboard-heading">
                            User Details
                        </p>

                        <table>
                            <tbody>
                                <tr>
                                    <td className="table-heading">
                                        <p className="dashboard-item">
                                            <strong>Name:</strong>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="dashboard-item">
                                            {profile && profile.name}
                                        </p>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="table-heading">
                                        <p className="dashboard-item">
                                            <strong>Email:</strong>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="dashboard-item">
                                            {profile && profile.email}
                                        </p>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="table-heading">
                                        <p className="dashboard-item">
                                            <strong>#Invoices:</strong>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="dashboard-item">
                                            {profile && profile.submitted + profile.drafts}
                                        </p>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="table-heading">
                                        <p className="dashboard-item">
                                            <strong>#Submited:</strong>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="dashboard-item">
                                            {profile && profile.submitted}
                                        </p>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="table-heading">
                                        <p className="dashboard-item">
                                            <strong>#drafts:</strong>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="dashboard-item">
                                            {profile && profile.drafts}
                                        </p>
                                    </td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                </div>


                <div className="grid-section4">

                    <div className="company-details">
                        <p className="dashboard-heading-company">
                            Company Details
                        </p>

                        <table>
                            <tbody>

                                <tr>
                                    <td >
                                        <p className="dashboard-item">
                                            <strong>Company Name:</strong>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="dashboard-item">
                                            {profile && profile.info?.company}
                                        </p>
                                    </td>
                                </tr>

                                <tr>
                                    <td >
                                        <p className="dashboard-item">
                                            <strong>Street Address:</strong>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="dashboard-item">
                                            {profile && profile.info?.address}
                                        </p>
                                    </td>
                                </tr>

                                <tr>
                                    <td >
                                        <p className="dashboard-item">
                                            <strong>Suburb:</strong>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="dashboard-item">
                                            {profile && profile.info?.suburb}
                                        </p>
                                    </td>
                                </tr>

                                <tr>
                                    <td >
                                        <p className="dashboard-item">
                                            <strong>City:</strong>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="dashboard-item">
                                            {profile && profile.info?.city}
                                        </p>
                                    </td>
                                </tr>

                                <tr>
                                    <td >
                                        <p className="dashboard-item">
                                            <strong>Post Code:</strong>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="dashboard-item">
                                            {profile && profile.info?.code}
                                        </p>
                                    </td>
                                </tr>

                                <tr>
                                    <td >
                                        <p className="dashboard-item">
                                            <strong>Country:</strong>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="dashboard-item">
                                            {profile && profile.info?.country}
                                        </p>
                                    </td>
                                </tr>

                                <tr>
                                    <td >
                                        <p className="dashboard-item">
                                            <strong>GST #:</strong>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="dashboard-item">
                                            {profile && profile.info?.gst}
                                        </p>
                                    </td>
                                </tr>

                                <tr>
                                    <td >
                                        <p className="dashboard-item">
                                            <strong>Bank Account #:</strong>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="dashboard-item">
                                            {profile && profile.info?.acc}
                                        </p>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Dashboard;
