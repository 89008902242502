import React, { useState, useEffect } from 'react';
import '../css/NewDocket.css';
import CustomButton from "../components/CustomButton";
import { getCurrentlySignedInUser, getDocket, getDB } from '../api/FirebaseAPI';
import { useHistory } from 'react-router-dom';

const EditDocket = ({ match }) => {
    const [to, setTo] = useState('');
    const [issued, setIssued] = useState('');
    const [due, setDue] = useState('');
    const [company, setCompany] = useState('');
    const [address, setAddress] = useState('');
    const [suburb, setSuburb] = useState('');
    const [city, setCity] = useState('');
    const [code, setCode] = useState('');
    const [country, setCountry] = useState('');
    const [error, setError] = useState("");
    const [gst, setGST] = useState("");
    const [acc, setAcc] = useState("");

    //billing to fields
    const [cAddress, setCAddress] = useState('');
    const [cSuburb, setCSuburb] = useState('');
    const [cCity, setCCity] = useState('');
    const [cPostCode, setCPostCode] = useState('');
    const [cCountry, setCCountry] = useState('');

    // const [profile, setProfile] = useState(false);
    const [user, setUser] = useState();
    // const [loading, setLoading] = useState(true);


    let history = useHistory();

    useEffect(() => {
        getCurrentlySignedInUser(setupUser, () => redirectToLogin());
    }, [user]);

    const setupUser = (user) => {
        setUser(user);

        getDocket(user.uid, match.params.id, (doc) => {
            setupValue(doc.data());
        }, (error) => {
            console.log('Error: ', error)
        })

    }

    const redirectToLogin = () => {
        history.push("/");
    }

    const updateAcc = e => {
        setAcc(e.target.value);
        setError("");
    }

    const updateGST = e => {
        setGST(e.target.value);
        setError("");
    }

    const updateTo = (e) => {
        setTo(e.target.value);
        setError("");
    }

    const updateIssued = (e) => {
        setIssued(e.target.value);
        setError("");
    }

    const updateDue = (e) => {
        setDue(e.target.value);
        setError("");
    }

    const updateCompany = (e) => {
        setCompany(e.target.value);
        setError("");
    }

    const updateAddress = (e) => {
        setAddress(e.target.value);
        setError("");
    }

    const updateSuburb = (e) => {
        setSuburb(e.target.value);
        setError("");
    }

    const updateCity = (e) => {
        setCity(e.target.value);
        setError("");
    }

    const updateCode = (e) => {
        setCode(e.target.value);
        setError("");
    }

    const updateCountry = (e) => {
        setCountry(e.target.value);
        setError("");
    }

    const setupValue = (data) => {
        setTo(data.to);
        setIssued(data.issued);
        setDue(data.due);
        setCompany(data.company);
        setAddress(data.address);
        setSuburb(data.suburb);
        setCity(data.city);
        setCode(data.code);
        setCountry(data.country);
        setAcc(data.acc);
        setGST(data.gst);
        setCAddress(data.customer_address);
        setCSuburb(data.customer_suburb);
        setCCity(data.customer_city);
        setCPostCode(data.customer_postcode);
        setCCountry(data.customer_country);
    }

    const updateCustomerAddress = e => {
        if (e.target.id == 'caddress') {
            setCAddress(e.target.value);
        }
        if (e.target.id == 'csuburb') {
            setCSuburb(e.target.value);
        }
        if (e.target.id == 'ccity') {
            setCCity(e.target.value);
        }
        if (e.target.id == 'cpostcode') {
            setCPostCode(e.target.value);
        }
        if (e.target.id == 'ccountry') {
            setCCountry(e.target.value);
        }
    }

    const submit = () => {
        getDB().collection('dockets: ' + user.uid).doc(match.params.id).update({
            to: to,
            issued: issued,
            due: due,
            company: company,
            address: address,
            suburb: suburb,
            city: city,
            code: code,
            country: country,
            gst: gst,
            acc: acc,
            customer_address: cAddress,
            customer_suburb: cSuburb,
            customer_city: cCity,
            customer_postcode: cPostCode,
            customer_country: cCountry
        }).then(() => {
            //console.log("document updated");
            history.push('/dockets');
        }).catch(error => {
            console.log("Error updating doc: ", error);
        })
    }

    const validate = () => {
        if (to.trim() === '') return false;
        if (issued.trim() === '') return false;
        if (due.trim() === '') return false;
        if (company.trim() === '') return false;
        if (address.trim() === '') return false;
        if (suburb.trim() === '') return false;
        if (city.trim() === '') return false;
        if (code.trim() === '') return false;
        if (country.trim() === '') return false;

        return true;
    }

    return (
        <div className="newdocket-container">
            <div className="newdocket-content">
                <div className="newdocket-section1">
                    <p className="dashboard-heading">
                        New Invoice
                    </p>
                    <div className="customer-billing">
                        <p className="dashboard-heading">
                            Customer Billing
                        </p>

                        <form action="">
                            <input type="text" placeholder="To" value={to} onChange={updateTo} autoComplete="off" />
                            <input type="text" id="caddress" placeholder="Address" value={cAddress} onChange={updateCustomerAddress} autoComplete="off" />
                            <input type="text" id="csuburb" placeholder="Suburb" value={cSuburb} onChange={updateCustomerAddress} autoComplete="off" />
                            <input type="text" id="ccity" placeholder="City" value={cCity} onChange={updateCustomerAddress} autoComplete="off" />
                            <input type="text" id="cpostcode" placeholder="Post Code" value={cPostCode} onChange={updateCustomerAddress} autoComplete="off" />
                            <input type="text" id="ccountry" placeholder="Country" value={cCountry} onChange={updateCustomerAddress} autoComplete="off" />
                            <input type="text" placeholder="Issued" value={issued} onChange={updateIssued} autoComplete="off" />
                            <input type="text" placeholder="Due" value={due} onChange={updateDue} autoComplete="off" />

                            <input onClick={submit} type="submit" value="Update" className="newdocket-button" />
                            {error !== "" ? <p className="newdocket-error">{error}</p> : null}
                        </form>

                    </div>
                </div>

                <div className="newdocket-section2">
                    <div className="newdocket-company-details">
                        <p className="dashboard-heading-company">
                            Company Details
                        </p>

                        <form action="">
                            <input type="text" placeholder="Company" value={company} onChange={updateCompany} autoComplete="off" />
                            <input type="text" placeholder="Address" value={address} onChange={updateAddress} autoComplete="off" />
                            <input type="text" placeholder="Suburb" value={suburb} onChange={updateSuburb} autoComplete="off" />
                            <input type="text" placeholder="City" value={city} onChange={updateCity} autoComplete="off" />
                            <input type="text" placeholder="Post Code" value={code} onChange={updateCode} autoComplete="off" />
                            <input type="text" placeholder="Country" value={country} onChange={updateCountry} autoComplete="off" />
                            <input type="text" placeholder="GST #" value={gst} onChange={updateGST} autoComplete="off" />
                            <input type="text" placeholder="Bank Account" value={acc} onChange={updateAcc} autoComplete="off" />

                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default EditDocket;