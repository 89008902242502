import React from 'react';
import '../css/Spinner.css';

const Spinner = ({ size }) => {
    return (
        <div className="spinner-container">
            <div style={{ width: size, height: size }} className="ball ball1"></div>
            <div style={{ width: size, height: size }} className="ball ball2"></div>
            <div style={{ width: size, height: size }} className="ball ball3"></div>
        </div>
    );
}

export default Spinner;