import React, { useState, useEffect } from 'react';
import "../css/Nav.css";
import { useHistory, useLocation } from 'react-router-dom';
import { signOut } from '../api/FirebaseAPI';
import { currentUser } from '../States';
import { useRecoilValue } from 'recoil';
import { BiHome, BiMessageDetail, BiLogOut } from 'react-icons/bi';
import { FaFileInvoice } from 'react-icons/fa';
import { AiFillFileAdd } from 'react-icons/ai';
import { MdGroup } from 'react-icons/md';
import Logo from '../images/icon-2.svg';

function Nav({ user }) {

    const myUser = useRecoilValue(currentUser);
    const [visible, setVisible] = useState(false);
    const [active, setActive] = useState("none");

    const [classes, setClasses] = useState("mobile-hidden");

    const history = useHistory();
    const location = useLocation();


    const logout = () => {
        signOut(() => {

        })
    }

    useEffect(() => {
        if (myUser) {
            setVisible(true);
            console.log(myUser);
        } else {
            setVisible(false);
        }
    }, [myUser]);

    useEffect(() => {
        if (myUser) {
            setActive(location.pathname);
        }
    }, [location])

    const toggleClasses = () => {
        if (classes == "") {
            setClasses("mobile-hidden");
        } else {
            setClasses("");
        }
    }

    return (
        <>
            {
                visible ?
                    <div className="nav-container" onClick={toggleClasses}>
                        <img src={Logo} width="40" heigth="40" />
                        <div className={"nav-icon-wrapper " + classes}>
                            <BiHome className={active === "/dashboard" ? "active" : ""} onClick={() => history.push("/dashboard")} />
                            <FaFileInvoice className={active === "/dockets" ? "active" : ""} onClick={() => history.push("/dockets")} />
                            <AiFillFileAdd className={active === "/newdocket" ? "active" : ""} onClick={() => history.push("/newdocket")} />
                            <BiMessageDetail className={active === "/info" ? "active" : ""} onClick={() => history.push("/info")} />
                            <MdGroup className={active === "/clients" ? "active" : ""} onClick={() => history.push("/clients")} />
                            <BiLogOut onClick={logout} />
                        </div>
                    </div>
                    :
                    null
            }
        </>
    );
}

export default Nav;