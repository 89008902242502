import React, { useEffect } from 'react';
import { currentUser } from '../States';
import { useSetRecoilState } from 'recoil';
import { getAuth } from '../api/FirebaseAPI';
import { useHistory } from 'react-router-dom';

const Auth = () => {

    const setUser = useSetRecoilState(currentUser);

    let history = useHistory();

    useEffect(() => {
        var auth = getAuth();
        auth.onAuthStateChanged(myUser => {
            if (myUser) {
                setUser(myUser);
                console.log("From Auth: user logged in");
                history.push("/dashboard");
            } else {
                setUser(undefined);
                history.push("/");
                console.log("From Auth: user logged out");
            }
        });
    }, []);

    return (
        null
    );
}

export default Auth;