import React, { useState } from 'react';

import "../css/SignUp.css";
import Spinner from './Spinner';
import { createUser, createUserProfile } from '../api/FirebaseAPI';
import { useHistory } from 'react-router-dom';

const SignUp = () => {


    const [registerForm, setRegisterForm] = useState({
        display: '',
        email: '',
        password: '',
        confirm: '',
    });

    const [error, setError] = useState('');
    const [submitting, setSubmitting] = useState(false);

    let history = useHistory();

    const updateInput = event => {
        let name = event.target.name;
        let value = event.target.value;
        let form = { ...registerForm, [name]: value }
        setRegisterForm(form);
        setError('');
    }

    const handleRegister = event => {
        event.preventDefault();
        setSubmitting(true);

        if (validateInput()) {

            createUser(registerForm.email, registerForm.password, cred => {
                const data = { name: registerForm.display, email: registerForm.email };
                createUserProfile(data, cred.user.uid);
                history.push('/dashboard');
            }, (e) => {
                setError(e)
            })

        }

    }

    const validateInput = () => {
        if (registerForm.password !== registerForm.confirm) {
            setError('Passwords must match!');
            return false;
        }

        if (registerForm.password.trim().length < 6) {
            setError("Password must be 6 chars or more!");
            return false;
        }

        if (registerForm.display.trim().length < 3) {
            setError("Name must be 3 chars or more!");
            return false;
        }

        return true;

    }

    return (
        <div className="signup-form">
            <h3>Create your account</h3>
            <input type="text" name="display" placeholder="Name" onChange={updateInput} />
            <input type="email" name="email" placeholder="Email" onChange={updateInput} />
            <input type="password" name="password" placeholder="Password" onChange={updateInput} />
            <input type="password" name="confirm" placeholder="Confirm Password" onChange={updateInput} />
            <span className="loading" onClick={handleRegister} >{submitting ? <Spinner size="10px" /> : "Create Account"}</span>
            {error && <p className="error">{error}</p>}
        </div>
    );
}

export default SignUp;