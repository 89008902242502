import React, { useEffect, useState } from 'react';
import '../css/Submition.css'
import { useRecoilState } from 'recoil';
import { currentInvoice } from '../States';
import { useHistory } from 'react-router-dom';
import { getCurrentlySignedInUser, getUserProfile, incrementSubmit, decrementDocket, updateDocketInvoice } from '../api/FirebaseAPI';

const Submition = () => {
    const [data, setData] = useRecoilState(currentInvoice);

    const [user, setUser] = useState();
    const [profile, setProfile] = useState();
    const [response, setResponse] = useState('');
    const [loading, setLoading] = useState(true);

    let history = useHistory();

    useEffect(() => {
        getCurrentlySignedInUser(setupUser, () => redirectToLogin());

    }, []);

    const redirectToLogin = () => {
        history.push("/");
    }

    const setupUser = (user) => {
        setUser(user);
        getUserProfile().doc(user.uid).get().then((doc) => {
            if (doc.exists) {
                setProfile(doc.data());
            }
        });

    }

    useEffect(() => {
        if (profile) sendEmail();
    }, [profile]);

    const sendEmail = () => {

        var email = {
            ...data,
            invoiceNumber: profile.submitted + 1,
        };


        //create a new XMLHTTP request
        var xhr = new XMLHttpRequest();

        //get a callback when the server responds
        xhr.addEventListener('load', () => {
            // update the email status with the response
            console.log("response: ", xhr.responseText);
        });

        xhr.open('POST', 'https://makeiteasy.co.nz/invoice/index.php');
        xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');

        // xhr.open('GET', 'http://ntl.kiwi/sendemail/index.php?sendto=' + email.email +
        // '&name=' + email.name + "&auth=" + email.auth + '&message=' + email.message);

        xhr.onerror = function () { // only triggers if the request couldn't be made at all
            setResponse('Network error');
            setLoading(false);
        };

        //send request
        xhr.send(JSON.stringify(email));
        xhr.onload = () => {
            setResponse(xhr.response);
            setLoading(false);
            decrementDocket(user.uid);
            incrementSubmit(user.uid);
            updateDocketInvoice(user.uid, data.docketID, profile.submitted + 1);
        }

        console.log('email: ', email);

    }

    return (
        <div className="submition-container">
            <p>{response}</p>
            <p className="end-link" onClick={() => {
                history.push("/");
            }}>Return</p>
        </div>
    );
}

export default Submition