import React, { useState } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import "./TableForm.css";

const TableForm = ({ visible, onCancel, onSubmit }) => {

    //billing to fields
    const [name, setName] = useState('');
    const [cAddress, setCAddress] = useState('');
    const [cSuburb, setCSuburb] = useState('');
    const [cCity, setCCity] = useState('');
    const [cPostCode, setCPostCode] = useState('');
    const [cCountry, setCCountry] = useState('');
    const [error, setError] = useState("");


    const updateCustomerAddress = e => {
        if (e.target.id == 'caddress') {
            setCAddress(e.target.value);
        }
        if (e.target.id == 'csuburb') {
            setCSuburb(e.target.value);
        }
        if (e.target.id == 'ccity') {
            setCCity(e.target.value);
        }
        if (e.target.id == 'cpostcode') {
            setCPostCode(e.target.value);
        }
        if (e.target.id == 'ccountry') {
            setCCountry(e.target.value);
        }
        if (e.target.id == 'name') {
            setName(e.target.value);
        }
        setError("");
    }


    const submit = (e) => {
        e.preventDefault();

        if (!validate()) {
            setError("invalid inputs!");
            return;
        }

        let data = {
            name: name,
            createdAt: moment().format('MMMM Do YYYY'),
            address: cAddress,
            suburb: cSuburb,
            city: cCity,
            postcode: cPostCode,
            country: cCountry,
            id: uuidv4(),
        };

        onSubmit(data);
        clearInput();
    }

    const clearInput = () => {
        setName("");
        setCAddress("");
        setCSuburb("");
        setCCity("");
        setCPostCode("");
        setCCountry("");
    }

    const validate = () => {
        if (name.trim() === '') return false;
        if (cAddress.trim() === '') return false;
        if (cSuburb.trim() === '') return false;
        if (cCity.trim() === '') return false;
        if (cPostCode.trim() === '') return false;
        if (cCountry.trim() === '') return false;
        return true;
    }

    const cancel = (e) => {
        e.preventDefault();
        clearInput();
        onCancel();
    }


    return (
        <div style={container} className={visible ? "table-form" : "table-form collapsed"}>
            <h3 style={heading}>Add New Client</h3>

            <form style={form} action="" >
                <input className='table-form-input' type="text" id="name" placeholder="Name" value={name} onChange={updateCustomerAddress} autoComplete="off" />
                <input className='table-form-input' type="text" id="caddress" placeholder="Address" value={cAddress} onChange={updateCustomerAddress} autoComplete="off" />
                <input className='table-form-input' type="text" id="csuburb" placeholder="Suburb" value={cSuburb} onChange={updateCustomerAddress} autoComplete="off" />
                <input className='table-form-input' type="text" id="ccity" placeholder="City" value={cCity} onChange={updateCustomerAddress} autoComplete="off" />
                <input className='table-form-input' type="text" id="cpostcode" placeholder="Post Code" value={cPostCode} onChange={updateCustomerAddress} autoComplete="off" />
                <input className='table-form-input' type="text" id="ccountry" placeholder="Country" value={cCountry} onChange={updateCustomerAddress} autoComplete="off" />

                <div style={btnContainer}>
                    <input onClick={submit} type="submit" value="Add" className="table-form-button" />
                    <input onClick={cancel} type="submit" value="Cancel" className="table-form-button" />
                </div>
                {error !== "" ? <p className="newdocket-error">{error}</p> : null}
            </form>
        </div>
    );
}

export default TableForm;

const container = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    width: '90%',
    margin: '3px 0px 0px 5px',
    padding: '2%',
    boxSizing: 'border-box',
    fontFamily: 'var(--base-font)',
}

const heading = {
    display: 'flex',
    flexDirection: 'width',

    paddingLeft: '2%',
    color: 'var(--primary)'
}

const form = {
    display: 'flex',
    flexDirection: 'column'
}

const btnContainer = {
    display: 'flex',
    flexDirection: 'row',
    padding: '0% 1rem',
}